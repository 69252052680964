import locale9ec66352 from '../../lang/en.json'
import locale8db5ed1c from '../../lang/es.json'
import locale00be6ddc from '../../lang/pt.json'
import locale6c3fbcfd from '../../lang/ru.json'
import locale80459928 from '../../lang/th.json'
import locale275327d8 from '../../lang/fr.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","name":"English","iso":"en","file":"en.json","webUrl":"https://hotnovelpub.com"},{"code":"es","name":"Spain","iso":"es","file":"es.json","webUrl":"https://lightnoveldaily.com"},{"code":"pt","name":"Portugal","iso":"pt","file":"pt.json","webUrl":"https://lanovels.com"},{"code":"ru","name":"Russia","iso":"ru","file":"ru.json","webUrl":"https://eznovels.com"},{"code":"th","name":"Thailand","iso":"th","file":"th.json","webUrl":"https://thnovels.com"},{"code":"fr","name":"French","iso":"fr","file":"fr.json","webUrl":"https://nownovels.com"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","name":"English","iso":"en","file":"en.json","webUrl":"https://hotnovelpub.com"},{"code":"es","name":"Spain","iso":"es","file":"es.json","webUrl":"https://lightnoveldaily.com"},{"code":"pt","name":"Portugal","iso":"pt","file":"pt.json","webUrl":"https://lanovels.com"},{"code":"ru","name":"Russia","iso":"ru","file":"ru.json","webUrl":"https://eznovels.com"},{"code":"th","name":"Thailand","iso":"th","file":"th.json","webUrl":"https://thnovels.com"},{"code":"fr","name":"French","iso":"fr","file":"fr.json","webUrl":"https://nownovels.com"}],
  localeCodes: ["en","es","pt","ru","th","fr"],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'es.json': () => Promise.resolve(locale8db5ed1c),
  'pt.json': () => Promise.resolve(locale00be6ddc),
  'ru.json': () => Promise.resolve(locale6c3fbcfd),
  'th.json': () => Promise.resolve(locale80459928),
  'fr.json': () => Promise.resolve(locale275327d8),
}
