import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Login: () => import('../../components/Login/index.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c)),
  Layout: () => import('../../components/Layout/index.js' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c)),
  HomepageHotAuthor: () => import('../../components/Homepage/HotAuthor/index.vue' /* webpackChunkName: "components/homepage-hot-author" */).then(c => wrapFunctional(c.default || c)),
  HomepageListStory: () => import('../../components/Homepage/ListStory/index.vue' /* webpackChunkName: "components/homepage-list-story" */).then(c => wrapFunctional(c.default || c)),
  HomepageListStoryV1: () => import('../../components/Homepage/ListStoryV1/index.vue' /* webpackChunkName: "components/homepage-list-story-v1" */).then(c => wrapFunctional(c.default || c)),
  HomepageThumbnail: () => import('../../components/Homepage/Thumbnail/index.vue' /* webpackChunkName: "components/homepage-thumbnail" */).then(c => wrapFunctional(c.default || c)),
  LayoutFooter: () => import('../../components/Layout/Footer/index.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c)),
  LayoutHeader: () => import('../../components/Layout/Header/index.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c)),
  CommonActiveComponent: () => import('../../components/common/ActiveComponent/index.vue' /* webpackChunkName: "components/common-active-component" */).then(c => wrapFunctional(c.default || c)),
  CommonBreadcrumbs: () => import('../../components/common/Breadcrumbs/index.vue' /* webpackChunkName: "components/common-breadcrumbs" */).then(c => wrapFunctional(c.default || c)),
  CommonIconArrowDown: () => import('../../components/common/Icon/ArrowDown.vue' /* webpackChunkName: "components/common-icon-arrow-down" */).then(c => wrapFunctional(c.default || c)),
  CommonScrollTop: () => import('../../components/common/ScrollTop/index.vue' /* webpackChunkName: "components/common-scroll-top" */).then(c => wrapFunctional(c.default || c)),
  CommonStarIcon: () => import('../../components/common/StarIcon/index.vue' /* webpackChunkName: "components/common-star-icon" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
